import React, { useState, useEffect } from "react"
import { Modal, Button} from "react-bootstrap"
import * as style from "../../pages/agents/agent.module.css"
import { updateGroupName } from "../../components/Api/ListingApi"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToastPopup from "../../components/popup/ToastPopup";

export default function UpdateGroup(props) {
    const { show, toggleShow,networklist,reload} = props
    const Names = props.networklist.name
  const [fields, setfields] = useState("")
    const [fieldserror, setErrorFields] = useState("")
    const [success, setSucess] = useState(false);
    const[messages,setMessages]=useState("")
    const toggleSuccess = () => setSucess(p => !p);
    useEffect(() => {
      setfields(Names);
    }, [Names]);
    
   
    const handleChange = e => {
        setfields(e.target.value)
        setErrorFields("")
      }
    const ValidateFields = () =>{
    
     
      if(fields===""){
        setErrorFields("Please Enter Your Group Name")
      }
      else{
        setErrorFields("")
        
      }
    }
    const handleUpdate = async e => {
     
        ValidateFields()

      let id=networklist._id
      let getParams = {
          group_name: fields,
        }
        await updateGroupName(id,getParams).then((res) => {
           reload()
           toggleShow()
      setMessages(res.data.message)
          
           if(res.data.status===1){
            if (res.status === 200) {
              toggleSuccess()
            }
            
           }
           else{
              toggleSuccess()
           }
           
        
        }).catch((error) => {
          console.log(error)
        })
      }
      
    
return (
    <div>
      <ToastContainer />
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />

      <Modal show={show} onHide={toggleShow} className='ModalDiv'>
        <div className="PopUpBorderDiv">
        <Modal.Header closeButton className="team-popup-margin teampopup-title">
          <Modal.Title className="form-field-label form-label-team ">
          Edit Group Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0">
          <div className={style.overallBack}>
            <div className={style.createInBack}>
              <label className={style.createText} htmlFor="name">New Group Name</label>
              <br />
                      
              <input type="text" onChange={handleChange} value={fields} id="AgentProfileSignUpFormGroup" className={style.createInput} maxLength={30} />

            </div>
            <span style={{color:"red"}}>{fieldserror}</span>
            <div className={style.doneBack}>
              <Button className={style.doneButton} variant="primary" onClick={() => { handleUpdate(); }}>
                Update
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="team-popup-margin teampop-footer"></Modal.Footer>
        </div>
      </Modal>
    </div>
  )
}