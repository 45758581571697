// extracted by mini-css-extract-plugin
export var AgentCard = "agent-module--AgentCard--2a7dc";
export var aboutMe = "agent-module--aboutMe--652bf";
export var aboutText = "agent-module--aboutText--99667";
export var agenttourcancel = "agent-module--agenttourcancel--c4b25";
export var agenttourchat = "agent-module--agenttourchat--121ca";
export var agenttourmodify = "agent-module--agenttourmodify--3fdc2";
export var attachment = "agent-module--attachment--605ab";
export var bgtestimonial = "agent-module--bgtestimonial--f5238";
export var buttonBack = "agent-module--buttonBack--56f03";
export var buyerdetailimage = "agent-module--buyerdetailimage--9051d";
export var buyerdetailprice = "agent-module--buyerdetailprice--e9308";
export var buyertourmodify = "agent-module--buyertourmodify--d63c4";
export var cardname = "agent-module--cardname--49569";
export var circle = "agent-module--circle--25fd7";
export var createBack = "agent-module--createBack--6ca55";
export var createInBack = "agent-module--createInBack--03254";
export var createInBack1 = "agent-module--createInBack1--c9f39";
export var createInBack2 = "agent-module--createInBack2--0f266";
export var createInput = "agent-module--createInput--bd664";
export var createInput2 = "agent-module--createInput2--84b49";
export var createInput3 = "agent-module--createInput3--b303f";
export var createInput4 = "agent-module--createInput4--6ef8e";
export var createText = "agent-module--createText--8f697";
export var createText1 = "agent-module--createText1--2acbe";
export var detail = "agent-module--detail--e1e96";
export var divCont = "agent-module--divCont--ee643";
export var doneBack = "agent-module--doneBack--61390";
export var doneButton = "agent-module--doneButton--968c1";
export var editIcon = "agent-module--editIcon--74428";
export var favorite1 = "agent-module--favorite1--9497b";
export var fileInput = "agent-module--fileInput--2c7ba";
export var fileView = "agent-module--fileView--69a34";
export var fileupload = "agent-module--fileupload--e780e";
export var fileupload1 = "agent-module--fileupload1--72ef9";
export var fileuploadImg = "agent-module--fileuploadImg--a5066";
export var fileuploadImg1 = "agent-module--fileuploadImg1--b04c8";
export var fileuploadImgNew = "agent-module--fileuploadImgNew--6c707";
export var fileuploadNew = "agent-module--fileuploadNew--b2624";
export var groupButton1 = "agent-module--groupButton1--4ac72";
export var groupButton2 = "agent-module--groupButton2--e101a";
export var headbelText = "agent-module--headbelText--93590";
export var home = "agent-module--home--14f8f";
export var homePrice = "agent-module--homePrice--d4ca6";
export var homePrice1 = "agent-module--homePrice1--b1afc";
export var homes = "agent-module--homes--fb2e2";
export var iconBack = "agent-module--iconBack--f5e5f";
export var inputField = "agent-module--inputField--fe79b";
export var inputField1 = "agent-module--inputField1--8cd7e";
export var johnImage = "agent-module--johnImage--06272";
export var johnText = "agent-module--johnText--790d1";
export var johnback = "agent-module--johnback--623ae";
export var johncontent = "agent-module--johncontent--d2798";
export var joinBack = "agent-module--joinBack--58f8a";
export var joinsearch = "agent-module--joinsearch--00634";
export var linkTag = "agent-module--linkTag--cdbf8";
export var logoIcon = "agent-module--logoIcon--86371";
export var maskText = "agent-module--maskText--eb097";
export var maskText2 = "agent-module--maskText2--d0822";
export var myshow = "agent-module--myshow--47e82";
export var overallBack = "agent-module--overallBack--a2d69";
export var plusBack = "agent-module--plusBack--8fb94";
export var proText = "agent-module--proText--6df19";
export var profilePanel = "agent-module--profilePanel--7bfbf";
export var profilelImage = "agent-module--profilelImage--d9a9e";
export var questionIcon = "agent-module--questionIcon--2a23f";
export var quote = "agent-module--quote--a7105";
export var rect = "agent-module--rect--62902";
export var reviewtest = "agent-module--reviewtest--cc19d";
export var secondBack = "agent-module--secondBack--48575";
export var selectdate = "agent-module--selectdate--c5ab8";
export var sellertoursTitle = "agent-module--sellertours-title--c05ca";
export var showcase = "agent-module--showcase--381f6";
export var showcase1 = "agent-module--showcase1--147c5";
export var showcasetext = "agent-module--showcasetext--59a50";
export var signText = "agent-module--signText--ce9e8";
export var signup = "agent-module--signup--5d46d";
export var signup1 = "agent-module--signup1--797cf";
export var signupimage = "agent-module--signupimage--83ec7";
export var socialIcon = "agent-module--socialIcon--b28fc";
export var socialIcon1 = "agent-module--socialIcon1--f4952";
export var testCard = "agent-module--testCard--93c60";
export var testImage = "agent-module--testImage--ff7cd";
export var testmonial = "agent-module--testmonial--41204";
export var testtext = "agent-module--testtext--cd333";
export var textInput1 = "agent-module--textInput1--eca7f";
export var textInputs = "agent-module--textInputs--bc20a";
export var textInputs1 = "agent-module--textInputs1--76910";
export var textInputs12 = "agent-module--textInputs12--ecdf6";
export var textInputs2 = "agent-module--textInputs2--13c47";
export var thisText = "agent-module--thisText--6a290";
export var tourbuyer = "agent-module--tourbuyer--ca387";
export var tourincredibletext = "agent-module--tourincredibletext--2834e";